mat-icon.verified {
  color: var(--theme-verified-color);
}

mat-icon.verified-small {
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: var(--theme-verified-color);
}

mat-icon.blockchain {
  color: var(--theme-blockchain-color);
}

mat-icon.blockchain-small {
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: var(--theme-blockchain-color);
}

mat-icon.done {
  color: var(--theme-success-color);
}

mat-icon.error {
  color: var(--warn-color);
}

// Hide Google reCaptcha v3 badge
// Source: https://developers.google.com/recaptcha/docs/faq
.grecaptcha-badge {
  visibility: hidden;
}

// TODO(JOAO) Remove once we move way from GetSocial
// Hide specific social network buttons and their containing divs
.gs-buttons.gs-flat-colors:not(.gs-outline-colors):not(.gs-icon-highlight) a[class*='gs-'].gs-ok,
.gs-buttons.gs-flat-colors:not(.gs-outline-colors):not(.gs-icon-highlight) a[class*='gs-'].gs-vk,
.gs-buttons.gs-flat-colors:not(.gs-outline-colors):not(.gs-icon-highlight)
  div:has(> a[class*='gs-'].gs-ok),
.gs-buttons.gs-flat-colors:not(.gs-outline-colors):not(.gs-icon-highlight)
  div:has(> a[class*='gs-'].gs-vk) {
  display: none !important;
}
