.accredible-rich-text-wrapper {
  line-height: 1.25;

  // CSS reset for Prince XML
  ul,
  ol,
  li,
  p {
    margin: 0;
    padding: 0;
  }

  ul,
  ol {
    display: inline-block;
    margin: 5px 0 5px;
    line-height: inherit;
    padding-inline-start: 30px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  p:empty::after {
    content: '\00A0';
  }
}
