.chart-legend {
  .horizontal-legend {
    li {
      display: block !important;
    }
  }
}

ngx-charts-line-chart {
  .ngx-charts .line-highlight {
    display: block !important;
  }

  /* Make lines in line chart thick, with rounded ends */
  g.line-chart > g:last-of-type > g:nth-child(n) g.line-series > path {
    stroke-width: 3;
    stroke-linecap: round;
  }
}

ngx-charts-chart {
  .ngx-charts-outer {
    ngx-charts-legend {
      > div {
        > div {
          .legend-labels {
            background-color: rgba(0, 0, 0, 0.02);

            li {
              color: var(--theme-text-color);
            }
          }
        }
      }
    }
  }
}

// Necessary for mobile to prevent charts overlapping
ngx-charts-bar-vertical {
  &.mobile {
    min-height: 415px;

    &.employers {
      min-height: 465px;
    }
  }
}

.ngx-charts {
  text {
    fill: var(--theme-text-color);
  }
}
